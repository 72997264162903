import { rDate } from '@/utils'

export const parseTableData = (data, columns, fields, related) => {
  const getRelatedValue = (value, reflection) => {
    const { source_id, name } = reflection
    const relatedItem = related[name] || Object.values(related)?.find(r => {
      return r.id === source_id || r.id === name
    })

    return relatedItem?.data?.find(d => d.id === value)?.name || '—'
  }
  return data?.map((d, i) => {
    const item = { id: d.id }

    const type = d.act_type?.name

    columns.forEach(c => {
      const reflection = fields[c]?.reflection

      if (c === 'eliminated') {
        item[c] = d[c] ? 'Устранено' : 'Не устранено'
      } else if (c === 'no') {
        item[c] = i + 1
      } else if (c === 'act_no') {
        const year = d.act_date ? String(d.act_date)?.substring(2) : '—'
        let value = d.act_no + '/' + year + ' БДД'

        if (type === 'Предписание') {
          value = 'АП ' + value
        }

        item[c] = value
      } else if (reflection?.type) {
        switch (reflection.type) {
          case 'belongs_to':
            item[c] = getRelatedValue(d[c], reflection)
            break
          default:
            item[c] = d[c]
            break
        }
      } else if (fields[c]?.type === 'datetime') {
        item[c] = d[c] ? rDate.format(d[c]) : '—'
      } else if (fields[c]?.type === 'integer') {
        item[c] = d[c] || d[c] === 0 ? d[c] : '—'
      } else {
        item[c] = d[c] || '—'
      }
    })

    return item
  }) || []
}
