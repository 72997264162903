export const tableFields = {
  no: '№',
  created_at: 'Дата создания',
  week: 'Календарная неделя',
  act_no: 'Номер акта',
  act_type_id: 'Тип акта',
  subproject_id: 'Подпроект',
  contractor_id: 'Подрядчик',
  subcontractor_id: 'Субподрядчик',
  transfer_week: 'Календарная неделя передачи акта',
  elimination_date: 'Срок устранения',
  eliminated: 'Статус',
  stage: 'Стадия',
  alleged_fine: 'Сумма предполагаемой штрафной санкции',
  fine: 'Сумма штрафной санкции',
  comment: 'Примечание'
}

export const columns = [
  'no',
  'created_at',
  'week',
  'act_no',
  'act_type_id',
  'subproject_id',
  'contractor_id',
  'subcontractor_id',
  'transfer_week',
  'elimination_date',
  'eliminated',
  'stage',
  'alleged_fine',
  'fine',
  'comment'
]

export const customWidth = {
  comment: '320'
}

export const exportConfig = {
  column_order: [
    'created_at',
    'week',
    'name',
    'act_type.name',
    'subproject.name',
    'contractor.name',
    'subcontractor.name',
    'transfer_week',
    'elimination_date',
    'eliminated',
    'stage',
    'alleged_fine',
    'fine',
    'comment'
  ],
  column_labels: [
    'Дата создания',
    'Календарная неделя',
    'Номер акта',
    'Тип акта',
    'Подпроект',
    'Подрядчик',
    'Субподрядчик',
    'Календарная неделя передачи акта',
    'Срок устранения',
    'Статус',
    'Стадия',
    'Сумма предполагаемой штрафной санкции',
    'Сумма штрафной санкции',
    'Примечание'
  ],
  only: [
    'created_at',
    'week',
    'name',
    'transfer_week',
    'elimination_date',
    'eliminated',
    'stage',
    'alleged_fine',
    'fine',
    'comment'
  ],
  include: {
    act_type: {
      only: ['name']
    },
    subproject: {
      only: ['name']
    },
    contractor: {
      only: ['name']
    },
    subcontractor: {
      only: ['name']
    }
  }
}
