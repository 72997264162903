export const related = () => ({
  vehicles: {
    id: 'f09e59fb-ee58-482c-8b0f-9ac520cf2099',
    data: [],
    key: 'reg_number',
    config: {
      only: ['reg_number', 'id']
    }
  },
  employees: {
    id: 'a28c528a-64dd-41f9-94b9-aefd7485f55c',
    data: [],
    config: {
      include: { subcontractor: {} },
      only: ['name', 'id', 'position', 'is_prime']
    }
  },
  contractor: {
    id: 'ef021b44-a650-478e-a25f-ace1e9b545ca',
    data: [],
    config: { only: ['name', 'id'] }
  },
  subproject: {
    id: 'fe1656be-32eb-40e4-97f4-835fcd6ded4a',
    data: [],
    config: { only: ['name', 'id'] }
  },
  act_type: {
    id: 'public.act_types',
    data: [],
    config: { only: ['name', 'id'] }
  },
  bdd_type: {
    id: '06e0fb0f-6758-4b6a-923f-39a7c6f44c1b',
    data: [],
    config: { only: ['name', 'id'] }
  }
})
