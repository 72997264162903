export const filters = () => ([
  {
    id: 'created_at',
    source: null,
    initType: 'date-filter',
    type: 'day-filter',
    title: 'Дата создания',
    prop: { interval: { from: '', to: '' } },
    active: false
  },
  {
    id: 'act_type_id',
    source: 'act_type',
    type: 'checkbox-filter',
    title: 'Тип акта',
    prop: [],
    active: false
  },
  {
    id: 'contractor_id',
    source: 'contractor',
    type: 'checkbox-filter',
    title: 'Подрядчик',
    prop: [],
    active: false
  },
  {
    id: 'subcontractor_id',
    source: 'contractor',
    type: 'checkbox-filter',
    title: 'Субподрядчик',
    prop: [],
    active: false
  },
  {
    id: 'subproject_id',
    source: 'subproject',
    type: 'checkbox-filter',
    title: 'Подпроект',
    prop: [],
    active: false
  },
  {
    id: 'week',
    type: 'range-filter',
    title: 'Календарная неделя',
    prop: {
      interval: { from: '', to: '' },
      min: 1,
      max: 53,
      noControls: true
    },
    active: false
  },
  {
    id: 'transfer_week',
    type: 'range-filter',
    title: 'Календарная неделя передачи акта',
    prop: {
      interval: { from: '', to: '' },
      min: 1,
      max: 53,
      noControls: true
    },
    active: false
  },
  {
    id: 'elimination_date',
    source: null,
    initType: 'date-filter',
    type: 'day-filter',
    title: 'Срок устранения',
    prop: { interval: { from: '', to: '' } },
    active: false
  },
  {
    id: 'eliminated',
    source: null,
    type: 'radio-filter',
    title: 'Устранено',
    prop: [
      { id: 'all', label: 'Все' },
      { id: 'yes', label: 'Да' },
      { id: 'no', label: 'Нет' }
    ],
    active: false
  },
  {
    id: 'stage',
    type: 'string-filter',
    title: 'Стадия',
    prop: '',
    active: false
  },
  {
    id: 'alleged_fine',
    type: 'range-filter',
    title: 'Сумма предполагаемой штрафной санкции',
    prop: {
      interval: { from: '', to: '' },
      noControls: true
    },
    active: false
  },
  {
    id: 'fine',
    type: 'range-filter',
    title: 'Сумма штрафной санкции',
    prop: {
      interval: { from: '', to: '' },
      noControls: true
    },
    active: false
  },
  {
    id: 'comment',
    type: 'string-filter',
    title: 'Примечание',
    prop: '',
    active: false
  }
])
